import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 5000.000000 5000.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,5000.000000) scale(0.100000,-0.100000)">
     

     <path d="M24715 44533 c-2783 -93 -5352 -710 -7835 -1883 -3643 -1719 -6736
-4601 -8722 -8125 -252 -448 -600 -1134 -801 -1583 -978 -2181 -1538 -4438
-1692 -6812 -30 -471 -38 -816 -32 -1474 5 -631 10 -755 42 -1221 278 -3967
1769 -7772 4262 -10880 499 -622 865 -1029 1467 -1630 388 -388 609 -596 956
-900 1418 -1240 3009 -2272 4715 -3058 2217 -1020 4567 -1612 6995 -1762 791
-48 1707 -48 2485 0 5415 338 10408 2876 13901 7065 2092 2509 3530 5521 4168
8730 358 1800 461 3671 305 5535 -176 2105 -692 4167 -1529 6105 -214 496
-498 1085 -740 1535 -1265 2351 -3004 4429 -5108 6103 -2095 1667 -4520 2891
-7116 3591 -1434 387 -2898 605 -4436 661 -241 9 -1061 11 -1285 3z m1225
-2503 c781 -31 1449 -98 2185 -220 2899 -479 5626 -1700 7930 -3551 515 -414
878 -741 1401 -1263 525 -525 854 -891 1267 -1406 1974 -2462 3227 -5396 3631
-8500 103 -791 141 -1390 140 -2245 0 -699 -18 -1086 -80 -1705 -439 -4398
-2549 -8429 -5934 -11338 -2722 -2339 -6130 -3771 -9690 -4071 -868 -74 -1764
-82 -2625 -26 -3787 250 -7384 1756 -10240 4288 -520 462 -1097 1043 -1556
1567 -736 842 -1404 1776 -1965 2751 -1248 2166 -2010 4603 -2218 7094 -45
539 -51 690 -51 1450 0 776 6 925 56 1500 292 3375 1584 6588 3716 9240 1362
1695 3020 3114 4888 4182 2405 1376 5073 2142 7850 2252 286 12 1018 12 1295
1z"/>
<path d="M11140 30455 l0 -1165 2475 0 2475 0 0 -5875 0 -5875 1305 0 1305 0
0 5875 0 5875 2445 0 2445 0 0 1165 0 1165 -6225 0 -6225 0 0 -1165z"/>
<path d="M24670 31596 c0 -13 -227 -3171 -505 -7017 -278 -3847 -505 -7004
-505 -7016 l0 -23 1275 0 c701 0 1275 2 1275 3 0 30 631 8855 634 8862 2 6 8
-1 14 -15 8 -22 3331 -8109 3555 -8652 l81 -198 871 0 871 0 1818 4428 c1001
2435 1823 4431 1828 4437 5 5 8 5 8 -2 0 -13 101 -1434 415 -5842 118 -1657
215 -3015 215 -3017 0 -2 574 -4 1275 -4 l1275 0 0 24 c0 13 -18 270 -40 572
-22 302 -60 833 -85 1179 -25 347 -59 815 -75 1040 -167 2303 -810 11222 -810
11240 l0 25 -1267 0 -1268 -1 -2045 -5071 c-1125 -2790 -2060 -5110 -2079
-5157 l-35 -85 -17 39 c-10 22 -946 2343 -2081 5157 l-2063 5117 -1267 1
-1268 0 0 -24z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
